body {
  margin: 0px;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow: hidden;
}

.tabPanel {
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  align-items: stretch;
  /* flex-direction: column; */
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panelTabs {
  height: 100%;
}

.conversation {
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.query-params {
  font-family: monospace;
  line-height: 15px;
  padding: 5px;
}

.query-params span {
  margin-right: 5px;
  border-bottom: 1px dashed transparent;
  cursor: pointer;
}

.query-params span:hover {
  color: black;
  border-bottom: 1px dashed #f26419;
}

.header {
  background: #fff;
  border-bottom: 1px solid #ededed;
  height: 64px;
  color: #6b6b6b;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 64px;
}

.header-current-book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-current-book .bookid {
  font-size: large;
  color: #1c2126;
  margin-bottom: 3px;
}

.header-current-book .artifact {
  color: #f26419;
}

.history {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1rem;
}

.history .turn {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.history .bubble {
  padding: 1rem;
  background-color: #f5f6f8;
  border-radius: 1rem;
  max-width: 50%;
}

.history .question {
  align-self: flex-end;
  border-bottom-right-radius: 0;
  cursor: pointer;
}

.history .answer {
  align-self: flex-start;
  border-bottom-left-radius: 0;
  border: 1px solid #f47533;
  background-color: #f9ddd529;
}

.history .answer.inspect {
  cursor: pointer;
}

.history .answer.active {
  background-color: #f9ddd5;
  border: 1px solid transparent;
  /* cursor: unset; */
}

.history .question.active {
  background-color: #bbbbbb;
  border: 1px solid transparent;
  /* cursor: unset; */
}

.headerMenuPortal {
  z-index: 100000;
}

.headerMenuPopover {
  margin-top: 10px;
  border: 1px solid #ededed;
}

.header-logo {
  color: black;
  font-size: large;
  /* line-height: 48px; */
  /* margin-right: 30px; */
  margin-top: 3px;
  margin-left: 9px;
  margin-bottom: 3px;
  user-select: none;
}

#header-menu-root {
  display: flex;
}

.header-logo a:hover {
  text-decoration: none;
}

.header-section-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 10px;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  /* min-width: 300px; */
}

.usage-meter {
  width: 100px;
  margin-right: 10px;
}

.rightPanel {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  max-height: calc(100vh - 92px);
}

.nonIdealTab {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.header-section-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
}

.footer {
  background: #fff;
  border-top: 1px solid #ededed;
  height: 28px;
  color: #6b6b6b;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 28px;
}

.footer-section-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  line-height: 18px;
  user-select: none;
}

.footer-section-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.body {
  background: gray;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
  max-height: calc(100vh - 92px);
}

.sideBarResizeContainer {
  width: 30%;
}
.sidebar {
  background: #fff;
  min-width: 300px;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
}

.content {
  flex-grow: 5;
  background: white;
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  margin: auto -5px;
  height: 100%;
  background-color: grey;
  opacity: 0;
  text-align: center;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.customHandle.horizontal {
  cursor: ns-resize;
  width: 50px;
  height: 10px;
  margin: -5px 0px;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 1px;
  background: #ededed;
  display: flex;
  z-index: 10;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: visible;
}

.customResizeBorder:hover,
.customResizeBorder:focus,
.customResizeBorder:active {
  background: #f26419;
  outline: 1px solid #f264195e;
}
