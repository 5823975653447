:focus {
  outline: none;
}

/* //////////////////////// */
/* POPOVERS */
/* //////////////////////// */

.bp4-popover2 {
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 1px, rgb(107 107 107 / 10%) 0px 16px 24px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.bp4-submenu.bp4-popover > .bp4-popover-content {
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 1px, rgb(107 107 107 / 10%) 0px 16px 24px;
  border: 1px solid transparent;
  border-radius: 4px;
}

/* //////////////////////// */
/* BUTTONS */
/* //////////////////////// */

input.bp4-input {
  box-shadow: none;
  border: 1px solid #cacaca;
}

.bp4-large .bp4-input {
  border-radius: 6px;
}

input.bp4-input:focus {
  box-shadow: inset 0 0 0 1px #f47533, 0 0 0 2px rgb(244 117 51 / 25%), inset 0 1px 1px rgb(17 20 24 / 20%);
}

.bp4-button.bp4-intent-primary {
  background-color: #f47533;
}

.bp4-button.bp4-intent-primary:hover {
  background-color: #dc6f36;
}

.bp4-button.bp4-intent-primary:disabled,
.bp4-button.bp4-intent-primary.bp4-disabled {
  background-color: rgba(210, 133, 45, 0.5);
}

.bp4-button:not([class*='bp4-intent-']) {
  background-image: none;
}

.bp4-button.small {
  padding-top: 3px;
  padding-bottom: 3px;
  min-height: 20px;
  line-height: 1.15;
}

.bp4-button.white {
  box-shadow: none;
  background: #fff;
  border: 1px solid #ececec;
}

.bp4-button.white:hover {
  box-shadow: none;
  background: #fff;
  border: 1px solid #f47533;
}

.bp4-button.white.bp4-disabled {
  box-shadow: none;
  background: #fff;
  border: 1px solid #ececec;
}

.bp4-button.white.bp4-intent-primary {
  box-shadow: none;
  background: #fff;
  border: 1px solid #f47533;
  color: #f47533;
}

/* //////////////////////// */
/* MODALS */
/* //////////////////////// */

.bp4-overlay-backdrop {
  background-color: rgba(17, 20, 24, 0.4);
}

.bp4-dialog-header {
  background-color: #fdfdfd;
  box-shadow: 0 1px 0 rgb(17 20 24 / 5%);
}

.bp4-dialog .bp4-heading {
  color: #5c5c5c;
}

.bp4-dialog {
  background: #fff;
}

/* TABS */

.bp4-tab[aria-selected='true'],
.bp4-tab:not([aria-disabled='true']):hover {
  color: #f47533;
}

.bp4-tab[aria-selected='true'] {
  box-shadow: inset 0 -3px 0 #f47533;
}

/* ICONS */

.bp4-non-ideal-state-visual {
  color: rgb(205 162 139);
}
