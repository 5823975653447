.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    text-align: left;
    overflow: scroll;
}

.fact {
    font-size: small;
    text-align: left;
    margin-top: 5px;
}

.circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    line-height: 20px;
    vertical-align: middle;
    margin: -2px 5px 0 0;
}

.topic {
    line-height: 20px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.subtopic {
    font-weight: bold;
    font-size: smaller;
    border-bottom: 1px solid #ededed;
}

.facts {
    font-weight: normal;
    font-size: smaller;
    margin-bottom: 10px;
}