.editor {
  flex-grow: 1;
  color: #b1b1b1;
  height: calc(100% - 35px);
}

.editorContainer {
  width: 100%;
}

.btn {
  cursor: pointer;
  color: #b1b1b1;
  vertical-align: middle;
  float: right;
}

.btn :hover {
  color: #000;
}

.tabsContainer {
  display: flex;
  height: 30px;
  padding-left: 0px;
  background: #eeeeee;
}

.tab {
  background: #fff;
  width: fit-content;
  color: #333;
  height: 30px;
  display: flex;
  padding: 8px 10px 0px 10px;
  border-right: 2px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

.tab span {
  cursor: pointer;
  font-size: 0.8em;
  margin-right: 5px;
}

.active {
  border-bottom: 0px !important;
}

.active span {
  font-weight: bold;
}

.editorSidebar {
  background: #fff;
  min-width: 200px;
  width: 400px;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
}
