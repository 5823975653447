.query.pending input {
  background-color: #fefaf8;
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */

.dot-typing {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #f47533;
  color: #f47533;
  box-shadow: 9984px 0 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px 0 0 0 #f47533;
  animation: dotTyping 1.2s infinite ease-in-out;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px 0 0 0 #f47533;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px 0 0 0 #f47533;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px 0 0 0 #f47533;
  }
  50% {
    box-shadow: 9984px 0 0 0 #f47533, 9999px -10px 0 0 #f47533, 10014px 0 0 0 #f47533;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px 0 0 0 #f47533;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px -10px 0 0 #f47533;
  }
  100% {
    box-shadow: 9984px 0 0 0 #f47533, 9999px 0 0 0 #f47533, 10014px 0 0 0 #f47533;
  }
}

.feedback {
  position: absolute;
  right: -10px;
  display: flex;
  gap: 5px;
}

.bubble > .feedback > * {
  display: none;
}

.bubble > .feedback > .visible {
  display: flex;
}

.bubble {
  position: relative;
}

.bubble:hover > .feedback > * {
  display: flex;
}

.attachments {
  display: flex;
  flex-direction: row;
  margin: 7px 0;
  font-size: smaller;
}

.attachments .label {
  font-weight: bold;
  color: #f47533;
}

.attachments .attachment-item {
  margin: 0 10px;
}

.savedQueries {
  display: flex;
  position: relative;
}

.savedQueries:hover > div {
  display: block;
}

.trash {
  position: absolute;
  right: 0;

  display: none;
}
