.artefact {
  cursor: pointer;
  padding: 2px 4px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.artefact:hover {
  border-color: #ededed;
}

.avatar {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  cursor: pointer;
}

.avatar img {
  height: 2rem;
}

.user {
  padding: 10px;
  max-width: 200px;
  color: grey;
  font-size: small;
}

.toggle {
  margin-right: 15px;
  border: 1px solid #ededed;
  padding: 3px;
  border-radius: 5px;
  background: #ffffff;
}

.option {
  padding: 5px 8px;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
}

.option.active {
  font-weight: bold;
  background: #f36b23cc;
  color: white;
  cursor: default;
}
