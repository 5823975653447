.socialButton {
  margin-bottom: 5px;
}

.loginButton {
  margin: 20px 0 20px 0;
}

.error {
  margin: 0 0 10px 0;
}

.toggleLabel {
  margin: 5px 0 30px 0;
}

.or {
  text-align: center;
}

.socialContainer {
  text-align: center;
  margin-top: 20px;
}

.socialSubContainer {
  width: 220px;
  margin: 0 auto;
}
